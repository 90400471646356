<template>
    <div class="chat__box__note__area show">
        <div class="title">
            <p>{{ $t('Notes') }} <span class="attachment__subtitle"> <i class="eicon e-question"></i> {{ $t('Only team members can see notes.') }}</span></p>
        </div>
        <div class="details__card">
            <div class="note__block">
                <div class="details__card__body" id="noteBox">
                    <div v-for="(note, index) in notes" class="note-info">
                        <a href="javascript:void(0);" v-if="note.show_delete" @click.prevent="deleteNote(index, note.id)" class="erase-button">
                            <i class="eicon e-delete"></i>
                        </a>
                        <div class="note-admin-info d-flex justify-content-between">
                            <div class="note-creator"><strong>{{ note.creator }}</strong></div>
                            <div class="note-time" :title="note.created_at">{{ note.created_from }}</div>
                        </div>
                        <p class="label__full--eraseble text-break">{{ note.note }}
                            <span class="tag-container" v-if="note.tags.length>0">
                                <span v-for="tag in note.tags" class="tag-name" :class="tag.my_tag?'highlight':''">@{{tag.name}}</span>
                            </span>
                        </p>
                    </div>

                    <p v-if="isEmptyNotes" class="note-not-found text-danger">
                        {{ $t('No notes found.') }}
                    </p>
                </div>

                <div class="details__card_message">
                    <div class="details__card__wrap label__full--primary">
                        <textarea ref="note" :class="{ 'has-error': error.length }" @keyup="draftNotes" v-model="note" rows="5" :placeholder="$t('Add a note here')" class="label__full--primary form-control"></textarea>
                        <div class="error-message" v-text="error"></div>
                        <div class="form-action">
                            <multiselect
                                id="tags"
                                v-model="tags"
                                :options="companyManagers"
                                :placeholder="$t(`Tag a manager`)"
                                :multiple="true"
                                :taggable="false"
                                @input="draftNotes"
                                selectLabel=""
                                deselectLabel=""
                                track-by="id"
                                label="name"
                                :showLabels="false">
                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                    <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ $t('manager selected') }}</span>
                                </template>
                            </multiselect>
                            <note-submit-button :click="addNote" :type="'semi-button-info'" :loading="isLoading">{{ $t("Save")}}</note-submit-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import client from "../../app/api/Client";
import {mapActions, mapState} from "vuex";
import Multiselect from 'vue-multiselect';
import {debounce} from 'lodash';
import {PERMISSIONS} from "../../constants/enums";
import Vue from "vue";
import NoteSubmitButton from "../buttons/NoteSubmitButton";
import {UPDATE_PAGE_STATE} from "../../constants/action-type";

export default {
    props : ['focusNote'],

    components:{
        Multiselect,
        NoteSubmitButton
    },

    data() {
        return {
            error:'',
            isLoading:false,
            noteValue: '',
            noteTags: [],
        }
    },

    computed : {
        ...mapState(['company', 'pageState']),

        managers() {
            return this.pageState.managers;
        },

        notes() {
            return this.pageState.notes;
        },

        tags: {
            get() {
                this.noteTags = this.pageState?.draft_notes?.tags;
                return this.pageState?.draft_notes?.tags;
            },
            set(noteTags) {
                this.noteTags = noteTags;
            }
        },

        note: {
            get() {
                if(typeof this.pageState?.draft_notes?.note != 'undefined') {
                    this.noteValue = this.pageState?.draft_notes?.note;
                }
                return this.pageState?.draft_notes?.note;
            },
            set(noteValue) {
                this.noteValue = noteValue;
            }
        },

        companyManagers() {
            return this.pageState.managers?.filter(m => m.name !== '');
        },

        isEmptyNotes() {
            return this.pageState.notes?.length === 0;
        },

        applicantId() {
            return this.pageState.applicantId;
        }
    },

    methods : {
        ...mapActions([UPDATE_PAGE_STATE]),

        async addNote() {
            if (this.company.permission[PERMISSIONS.CANDIDATE_VIEW]) {

                if (this.noteValue.length < 1) {
                    this.error = this.$t('Please write some note!');
                    return false;
                }

                try {
                    this.isLoading = true;
                    let {data: {data, message}} = await client().post(`job/applicant/${this.applicantId}/note`, {
                        'note': this.noteValue,
                        'tags': this.noteTags
                    });

                    this.error = '';
                    this.resetNote();

                    let notes = this.pageState.notes;
                    let newNotes = notes.push(data);
                    this[UPDATE_PAGE_STATE](newNotes);

                    localStorage.removeItem(this.key);
                    this.$toast.success(this.$t(message));

                    Vue.nextTick(function () {
                        const div = document.getElementById('noteBox');
                        div.scrollTop = div.scrollHeight;

                    });

                    this.$nextTick(() => {
                        this.$refs.note.focus();
                    })

                } catch (error) {
                    this.error = error.response.data.message;
                }
            } else {
                this.$toast.error(this.$t('Sorry you do not have permission for this action.'));
            }
            this.isLoading = false;
        },

        async deleteNote(index, noteId) {
            let confirmMessage = {
                title : this.$t("Confirmation"),
                body : this.$t("Are you sure, you want to delete this note?")
            };

            let dialogConfig = {
                okText: this.$t('yes'),
                cancelText: this.$t('no'),
            };

            await this.$dialog.confirm(confirmMessage, dialogConfig).then(() => {
                if (this.company.permission[PERMISSIONS.CANDIDATE_VIEW]) {
                    client().delete(`job/applicant/${this.applicantId}/note/${noteId}/delete`)
                        .then(({data: {message}}) => {
                            this.error = '';

                            let notes = this.pageState.notes;
                            let newNotes = notes.splice(index, 1);
                            this[UPDATE_PAGE_STATE](newNotes);

                            this.$toast.success(this.$t(message));
                        }).catch(({response : { data }}) => {
                            this.$toast.error(data.message);
                        });
                } else {
                    this.$toast.error(this.$t('Sorry you do not have permission for this action.'));
                }
            });
        },

        resetNote() {
            this.noteValue = "";
            this.noteTags = [];
            this[UPDATE_PAGE_STATE]({draft_notes: {note: "", tags: []}});
            localStorage.removeItem(this.key);
        },

        draftNotes: debounce(function() {

            this[UPDATE_PAGE_STATE]({draft_notes: {note: this.noteValue, tags: this.noteTags}});

            if (this.company.permission[PERMISSIONS.CANDIDATE_VIEW]) {
                try {
                    client().post(`job/applicant/${this.applicantId}/draft-note`, {
                        'note': this.noteValue,
                        'tags': this.noteTags
                    });
                } catch (error) {
                    this.error = error.response.data.message;
                }
            }

        }, 1000)

    },

    created() {
        this.$nextTick(() => {
            const div = document.getElementById('noteBox');
            div.scrollTop = div.scrollHeight;
        });

        this.$watch('focusNote', function() {
            this.$nextTick(() => {
                this.$refs.note.focus();
            })
        });
    }
}
</script>

<style scoped>
    .message__wrapper .chat__box .chat__box__note__area{
        -webkit-box-flex:0;
        -ms-flex:0 0 300px;
        flex:0 0 300px;
        border-left:1px solid #f5f7fd;
        display:none;
        -webkit-transition:all .3s ease;
        transition:all .3s ease;
        background:#fff;
        border-radius: 10px;
    }
    .message__wrapper .chat__box .chat__box__note__area.show{
        display:block
    }
    .message__wrapper .chat__box .chat__box__note__area .title p {
        font-size:17px;
        font-weight:500;
        color:#1c2238;
        line-height: 1;
    }
    @media all and (max-width: 1199px){
        .message__wrapper .chat__box .chat__box__note__area{
            position:absolute;
            top:0;
            right:-300px;
            bottom:0;
            z-index:9;
            display:block
        }
        .message__wrapper .chat__box .chat__box__note__area.show{
            right:0
        }
    }

    .message__wrapper .chat__box .chat__box__note__area .title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: 15px;
        padding: 20px;
    }
    .message__wrapper .chat__box .chat__box__note__area .title .button {
        font-size: 12px;
        font-weight: 500;
        background: rgba(255,95,116,0.1);
        color: #ff5f74;
        padding: 5px 13px;
        border-radius: 5px;
        min-height: auto;
    }

    @media all and (max-width: 991px) {
        .message__wrapper .chat__box .chat__box__note__area .title p {
            font-size:13px;
        }
    }

    .message__wrapper .chat__box .chat__box__note__area .details__card .note__block .details__card__body::-webkit-scrollbar {
        width: 7px;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .note__block .details__card__body::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(125,128,145, 0.3);
        background-image: linear-gradient(to right bottom, rgba(89, 125, 252, 0.1), rgba(89, 125, 252, 0.1));
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .note__block .details__card__body::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #597dfc;
    }

    .message__wrapper .chat__box .chat__box__note__area .details__card {
        height: calc(100vh - 381px);
        min-height: 466px;
        overflow-y: auto;
        padding: 0;
    }

    .message__wrapper .chat__box .chat__box__note__area .details__card::-webkit-scrollbar {
        width: 7px;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(125,128,145, 0.3);
        background-image: linear-gradient(to right bottom, rgba(89, 125, 252, 0.1), rgba(89, 125, 252, 0.1));
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #597dfc;
    }

    @media all and (max-width: 1600px) {
        .message__wrapper .chat__box .chat__box__note__area .details__card {
            height: calc(100vh - 369px);
        }
    }
    @media all and (max-width: 1200px) {
        .message__wrapper .chat__box .chat__box__note__area .details__card {
            height: calc(100vh - 320px);
        }
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .note__block {
        height: calc(100vh - 528px);
        min-height: 321px;
    }
    @media all and (max-width: 1600px) {
        .message__wrapper .chat__box .chat__box__note__area .details__card .note__block {
            height: calc(100vh - 523px);
        }
    }
    @media all and (max-width: 1200px) {
        .message__wrapper .chat__box .chat__box__note__area .details__card .note__block {
            height: calc(100vh - 474px);
        }
    }
    @media all and (max-width: 991px) {
        .message__wrapper .chat__box .chat__box__note__area .details__card .note__block {
            height: calc(100vh - 466px);
        }
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__body {
        padding: 20px;
        overflow-y: auto;
        height: 100%;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__body .note-info {
        padding-bottom: 10px;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__body .note-info:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        margin-bottom: 10px;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__body .note-info .erase-button {
        z-index: 9;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__body .note-info .note-admin-info {
        margin-top: 0;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__body .note-info .label__full--eraseble {
        padding: 0 15px 0 0;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__body .tag-container .tag-name {
        font-size: 12px;
        color: #597dfc;
        display: block;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card_message {
        padding: 10px 10px 0;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__wrap {
        border-radius: 5px;
        position: relative;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__wrap textarea {
        background: transparent;
        box-shadow: none;
        outline: none;
        padding: 10px;
        height: 92px;
        border: 0 !important;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__wrap .form-action {
        display: flex;
        gap: 5px;
        padding: 0 5px 5px;
        align-items: center;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__wrap .form-action .select-option {
        flex: 1 1 auto;
        height: 35px;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__wrap .form-action .select-option:after {
        right: 10px;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__wrap .form-action .select-option .form-control {
        padding: 0 20px 0 10px;
        height: 35px;
    }
    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__wrap .form-action .button {
        height: 35px;
        min-height: 35px;
    }

    .note__subtitle {
        font-size: 12px !important;
        display: inline-block;
        color: #7d8091 !important;
        font-weight: 400 !important;
        width: 100% !important;
    }

    .note-not-found {
        text-align: center;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #ddd;
        font-size: 12px;
        margin-top: -20px;
    }

    .attachment__subtitle {
        font-size: 12px !important;
        display: flex;
        color: #7d8091 !important;
        font-weight: 400 !important;
        width: 100% !important;
        line-height: 16px;
    }

    .attachment__subtitle i.eicon {
        float: left;
        padding: 1px 2px 1px 1px;
        font-size: 10px;
        line-height: 14px;
    }

    .message__wrapper .chat__box .chat__box__note__area .details__card .details__card__wrap textarea {
        font-size: 14px;
        font-weight: 400 !important;
        color: #7d8091;
    }

    .chat__box__note__area .note__block .details__card__wrap .error-message {
        position: absolute;
        bottom: 40px;
        left: 5px;
    }

</style>
